import { ImagesDisplay } from './imagesDisplay'
import React, { useEffect, useState } from 'react'
import './HomePage.css'

import chelleeDean from '../src/imgs/thumnails/1.jpg'
import chellee from '../src/imgs/thumnails/2.jpg'
import danny from '../src/imgs/thumnails/3.jpg'
import gemma from '../src/imgs/thumnails/4.jpg'
import jaid from '../src/imgs/thumnails/5.jpg'
import jamie from '../src/imgs/thumnails/6.jpg'
import kristen from '../src/imgs/thumnails/7.jpg'
import lara from '../src/imgs/thumnails/8.jpg'
import mollie from '../src/imgs/thumnails/9.jpg'
import phyllis from '../src/imgs/thumnails/10.jpg'
import ruby from '../src/imgs/thumnails/11.jpg'
import sarah from '../src/imgs/thumnails/12.jpg'
import stefanie from '../src/imgs/thumnails/13.jpg'
import taylah from '../src/imgs/thumnails/14.jpg'
import wendy from '../src/imgs/thumnails/15.jpg'
import { galleryImages } from './GalleryImageSet'
import { DesktopComponent } from './Components/HomePageDesktop/HomePageDesktop'
import { galleryImagesMobile } from './GalleryImageSetMobile'
import { Divider } from 'antd'
import ScrollToTop from './Components/ScrollToTop/ScrollToTop'
import { useGalleryStore } from './Store/GalleryStore'
import { isMobile } from 'react-device-detect'
import mobileCover from '../src/imgs/mobileCover/1-1.jpg'
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from 'react-icons/io'
import { IoMdArrowRoundBack } from 'react-icons/io'

const thumbnails = [
  chelleeDean,
  chellee,
  danny,
  gemma,
  jaid,
  jamie,
  kristen,
  lara,
  mollie,
  phyllis,
  ruby,
  sarah,
  stefanie,
  taylah,
  wendy,
]

const albumNames = [
  'Chellee & Dean',
  'Gemma & Jamie',
  'Mollie & James',
  'Ruby & Tyson',
  'Danny & Dame',
  'Lara & Shaun',
  'Jaid & Mark',
  'Kristen & Darren',
  'Sarah & Jye',
  'Jamie & Tim',
  'Phyllis & Arthur',
  'Stefanie & Jayme',
  'Taylah & Sinclair',
  'Anni & Steve',
  'Wendy & Peter',
]

const albumLocations = [
  'Ballarat',
  'Mount Dandenong',
  'Melbourne',
  'Sunbury',
  'Melbourne',
  'Melbourne',
  'Melbourne',
  'Werribe',
  'Maleny',
  'Yarra Valley',
  'Geelong',
  'Melbourne',
  'Pennyroyal',
  'R.J. Hamer Arboretum',
  'Gum Gully Farm',
]

const albumVenues = [
  'Pipers by the lake',
  'Poet’s Lane Receptions',
  'Montsalvat',
  'Emu Bottom Homestead',
  'Royal Botanic Gardens',
  'Mont Du Soleil',
  'Crown Casino',
  'Werribe Mansion',
  'Maleny Manor',
  'Strathvea Guest House',
  'Campbell Point House',
  'Butler Lane by Peter Rowland',
  'Pennyroyal Otways Retreat',
  'Poet’s Lane Receptions',
  'Mount Macedon, St Kilda Beach',
]

interface Props {
  hideImageHeader?: boolean
  showGallery?: boolean
}

export const HomePage: React.FC<Props> = ({ hideImageHeader }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)
  const [selectedImageSetIndex, setSelectedImageSetIndex] = useState<
    number | null
  >(null)
  const isGalleryVisible = useGalleryStore((state) => state.isGalleryVisible)

  const showNextImage = () => {
    const albumImages = galleryImages[selectedImageSetIndex || 0]
    if (albumImages && currentIndex < albumImages.length - 1) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % albumImages.length)
    } else {
      // Reached the last image, loop back to the first image
      setCurrentIndex(0)
    }
  }

  const showPreviousImage = () => {
    const albumImages = galleryImages[selectedImageSetIndex || 0]
    if (albumImages && currentIndex > 0) {
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + albumImages.length) % albumImages.length
      )
    } else {
      // Reached the first image, go to the last image
      setCurrentIndex(albumImages.length - 1)
    }
  }

  const showNextAlbum = () => {
    if (selectedImageSetIndex !== null) {
      if (selectedImageSetIndex === galleryImagesMobile.length - 1) {
        // If it's the last album, loop back to the first album
        setSelectedImageSetIndex(0)
      } else {
        setSelectedImageSetIndex((prevIndex) => prevIndex! + 1)
      }
      setCurrentIndex(0)
    }
  }

  const showPreviousAlbum = () => {
    if (selectedImageSetIndex !== null) {
      if (selectedImageSetIndex === 0) {
        // If it's the first album, go to the last album
        setSelectedImageSetIndex(galleryImagesMobile.length - 1)
      } else {
        setSelectedImageSetIndex((prevIndex) => prevIndex! - 1)
      }
      setCurrentIndex(0)
    }
  }

  useEffect(() => {
    if (isGalleryVisible) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [isGalleryVisible])

  useEffect(() => {
    setCurrentIndex(0)
  }, [selectedImageSetIndex])

  return (
    <>
      {!isGalleryVisible && (
        <ImagesDisplay
          setSelectedImageSetIndex={setSelectedImageSetIndex}
          hideHeader={false}
        />
      )}

      {isMobile && isGalleryVisible && (
        <div className="gallery_container_mobile">
          <div className="location_detail">
            <p className="location-name">{`Location: ${
              albumLocations[selectedImageSetIndex || 0]
            },${albumVenues[selectedImageSetIndex || 0]}`}</p>
            <Divider style={{ margin: 5 }} />
            <div className="button-wrapper_mobile ">
              <button
                onClick={showPreviousAlbum}
                className="arrow-button_mobile"
                style={{ paddingRight: '20vw' }}
              >
                Back
              </button>
              <p className="album-name_mobile">
                {selectedImageSetIndex !== null &&
                  albumNames[selectedImageSetIndex]}
              </p>
              <button
                onClick={showNextAlbum}
                className="arrow-button_mobile"
                style={{ paddingRight: '9vw' }}
              >
                Next
              </button>
            </div>
          </div>
          {galleryImagesMobile[selectedImageSetIndex || 0].map(
            (image, index) => (
              <img
                key={index}
                className="gallery-image_mobile"
                style={{
                  width: '90vw',
                  margin: '0 auto',
                  position: 'relative',
                }}
                src={image}
                alt={` ${index + 1}`}
              />
            )
          )}
          <div>
            <ScrollToTop />
            <div style={{ paddingTop: '1vh' }}></div>
            <h3 style={{ textAlign: 'center', paddingBottom: '2vh' }}>
              More Featured Weddings
            </h3>
            <div className="custom-image-container">
              <img
                src={mobileCover}
                alt="Featured Wedding"
                style={{ width: '90vw', paddingRight: '4vw' }}
              />

              <button
                onClick={showPreviousAlbum}
                className="custom-arrow-button custom-arrow-left"
              >
                <IoIosArrowRoundBack />
              </button>

              <button
                onClick={showNextAlbum}
                className="custom-arrow-button custom-arrow-right"
              >
                <IoIosArrowRoundForward />
              </button>
            </div>
          </div>
        </div>
      )}
      {!isMobile && isGalleryVisible && (
        <DesktopComponent
          currentIndex={currentIndex}
          showPreviousImage={showPreviousImage}
          showNextImage={showNextImage}
          galleryImages={galleryImages}
          selectedImageSetIndex={selectedImageSetIndex}
          setSelectedImageSetIndex={setSelectedImageSetIndex}
          setHoveredIndex={setHoveredIndex}
          thumbnails={thumbnails}
          albumNames={albumNames}
          hoveredIndex={hoveredIndex}
        />
      )}
    </>
  )
}
