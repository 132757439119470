import { create } from 'zustand'

type State = {
  isGalleryVisible: boolean
}

type Action = {
  updateGalleryVisible: (isGalleryVisible: State['isGalleryVisible']) => void
}

// Create your store, which includes both state and (optionally) actions
export const useGalleryStore = create<State & Action>((set) => ({
  isGalleryVisible: false,
  updateGalleryVisible: (isGalleryVisible: boolean) =>
    set(() => ({ isGalleryVisible: isGalleryVisible })),
}))

// In consuming app
function App() {
  // "select" the needed state and actions, in this case, the firstName value
  // and the action updateFirstName
  const isGalleryVisible = useGalleryStore((state) => state.isGalleryVisible)
  const updateGalleryVisible = useGalleryStore(
    (state) => state.updateGalleryVisible
  )
  return (
    <main>
      <label>
        First name
        <input
          onChange={(e) =>
            updateGalleryVisible(e.currentTarget.value ? true : false)
          }
          value={isGalleryVisible.toString()}
        />
      </label>
    </main>
  )
}
