import React from 'react'
import './faqComponent.css'

const faqData = [
  {
    question: 'Why second shooter is important?',
    answer:
      ' At weddings, one photographer’s primary focus is often on the couple. But the participation of family and guests is equally vital for the overall ambience. Having a second shooter who can  capture moments with guests adds a more comprehensive touch to the entire wedding experience.',
  },
  {
    question: 'Why videography is important?',
    answer:
      'Videos have the power to convey emotions in a way that photos may not fully capture. The exchange of vows, the laughter, the tears, and other emotional moments are more vividly experienced through moving images and sound.',
  },
  {
    question: 'What if it rains on my wedding day?',
    answer:
      'Our camera is fully weather-resistant and capable of shooting on heavy rainy days. We also provide transparent wedding umbrellas that add an elegant touch to the photos. If during a storm or lightning, there is no suitable location in the venue for photography, we will offer you another two-hour location shoot and only charge $200 for travel fees (weekday only).',
  },
  {
    question: 'Do you travel to other cities?',
    answer:
      'Certainly, we’d love to fly to different destinations to capture various styles of wedding photos. I only charge $700, which is just enough to cover my flight tickets, accommodation, and car rental.',
  },
]

export const FAQComponent: React.FC = () => {
  const groupedQuestions = []

  for (let i = 0; i < faqData.length; i += 2) {
    groupedQuestions.push(faqData.slice(i, i + 2))
  }

  return (
    <div className="faq-container">
      <div className="faq-items">
        {groupedQuestions.map((group, index) => (
          <div className="faq-group" key={index}>
            {group.map((item, itemIndex) => (
              <div className="faq-item" key={itemIndex}>
                <h3 className="faq-question" style={{ fontSize: '1vw' }}>
                  {item.question}
                </h3>
                <p className="faq-answer">{item.answer}</p>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}
