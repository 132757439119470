import React from 'react'
import './Intro.css' // Assuming you have some CSS for styling
import test1 from '../src/imgs/about/1.jpg'
import IntroHeadPic from '../src/imgs/Intro/IntroHeadPic.jpg'

export const Intro: React.FC = () => {
  return (
    <>
      <div className="intro-container">
        <div className="intro-content">
          <div className="row">
            <div className="col">
              <img src={test1} alt="Image 1" className="introImage" />
            </div>
            <div className="col">
              <p style={{ fontSize: '1.3vw' }}>Style-</p>
              <p style={{ fontSize: '1.3vw', paddingTop: '1vw' }}>
                Every bride possesses a unique essence, and my expertise lies in
                unveiling the beauty that is exclusively yours.
              </p>
              <p style={{ fontSize: '1.3vw', paddingTop: '1vw' }}>
                Through eight years of wedding photography &amp; videography
                experience, I’ve come to realise that while replicating the
                composition might make the photos aesthetically pleasing, it
                also runs the risk of making them somewhat generic. This led me
                to shift my approach towards recognizing the distinctiveness of
                each bride, aiming to capture moments that are impossible to
                duplicate.
              </p>
              <p style={{ fontSize: '1.3vw', paddingTop: '1vw' }}>
                I started observing and capturing the individuality of each
                bride, moving away from traditional composition principles. This
                shift allows my photos to have minimal intentional composition,
                enabling them to be more natural, harmonious, and rich in the
                subject’s unique emotions and personal style.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="headPic-Container">
        <img src={IntroHeadPic} />
      </div>
      <div className="centered-text-container">
        <p>
          I understand the excitement of newlyweds waiting for their wedding
          photos. However, every time I finish a wedding shoot, I’m more excited
          to dive into reviewing the photos.
        </p>
        <p>
          Sometimes, even after finishing a shoot in the middle of the night, I
          eagerly scroll through the day’s captures on my computer. I can’t wait
          to see the final edited image than anyone else.
        </p>
        <p>So, you will see your wedding photos within a week :)</p>
      </div>
    </>
  )
}

export default Intro
