import React from 'react'
import './Portrait.css'
import { Divider } from '@mui/material'

// 导入图片
import anni from '../src/imgs/cover/1.jpg'
import chellee from '../src/imgs/cover/2.jpg'
import danny from '../src/imgs/cover/3.jpg'
import gemma from '../src/imgs/cover/4.jpg'
import jaid from '../src/imgs/cover/5.jpg'
import jamie from '../src/imgs/cover/6.jpg'
import kristen from '../src/imgs/cover/7.jpg'
import lara from '../src/imgs/cover/8.jpg'
import mollie from '../src/imgs/cover/9.jpg'
import phyllis from '../src/imgs/cover/10.jpg'
import ruby from '../src/imgs/cover/11.jpg'
import sarah from '../src/imgs/cover/12.jpg'
import stefanie from '../src/imgs/cover/13.jpg'
import taylah from '../src/imgs/cover/14.jpg'
import wendy from '../src/imgs/cover/15.jpg'

const images = [
  anni,
  chellee,
  danny,
  gemma,
  jaid,
  jamie,
  kristen,
  lara,
  mollie,
  phyllis,
  ruby,
  sarah,
  stefanie,
  taylah,
  wendy,
]

export const Portrait = () => {
  const divs = []
  for (let i = 0; i < 8; i++) {
    const rowImages = images.slice(i * 2, i * 2 + 2) // 每行最多显示三张图片
    divs.push(
      <div className="row" key={i}>
        {rowImages.map((imageUrl, index) => (
          <img
            src={imageUrl}
            alt={`Image ${i * 2 + index + 1}`}
            key={index}
            className="image"
          />
        ))}
      </div>
    )
  }

  return (
    <>
      <section id="headPic_section" className="headPic_image">
        <div className="hero_wrapper">
          <div className="container">
            <div className="headPic_section">
              <div className="row">
                <div className="col-md-12">
                  <div className="top_left_cont zoomIn wow animated"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="center-container">
        <p className="center-text">PORTRAIT</p>
      </div>
      <Divider />
      <div className="portrait-images-container">{divs}</div>
    </>
  )
}
