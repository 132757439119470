import { useState } from 'react'
import { Link } from 'react-router-dom'
import './NavBarMobile.css'
import { CloseOutlined } from '@ant-design/icons'
import { FaVimeo } from 'react-icons/fa'
import ins from '../../../src/imgs/logosWhite/ins.png'
import tiktok from '../../../src/imgs/logosWhite/tiktok.png'
import vim from '../../../src/imgs/logosWhite/vim.png'
import { useGalleryStore } from '../../Store/GalleryStore'

export const Navbar = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const setIsGalleryVisible = useGalleryStore(
    (state) => state.updateGalleryVisible
  )
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen)
  }
  const closeDropdown = () => {
    setDropdownOpen(false)
    setIsGalleryVisible(false)
  }

  return (
    <nav className={`navbar_mobile ${isDropdownOpen ? 'active' : ''}`}>
      <div className="burger-menu" onClick={toggleDropdown}>
        ☰
      </div>

      <ul
        className={`nav navbar_mobile_nav ${isDropdownOpen ? 'active' : ''}`}
        id="mainNav"
      >
        <CloseOutlined
          onClick={toggleDropdown}
          style={{
            alignSelf: 'end',
            fontSize: '22px',
            lineHeight: '34px',
            color: '#a6a6a6',
            height: '40px',
            paddingRight: '2vw',
            paddingBottom: '3vh',
          }}
        />
        <li onClick={closeDropdown}>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/Intro" onClick={closeDropdown}>
            Intro
          </Link>
        </li>
        <li>
          <Link to="/gallery" onClick={closeDropdown}>
            Photography
          </Link>
        </li>
        <li>
          <Link to="/videography" onClick={closeDropdown}>
            Videography
          </Link>
        </li>
        <li>
          <Link to="/pricingMobile" onClick={closeDropdown}>
            Pricing
          </Link>
        </li>
        <li>
          <Link to="/contactPageMobile" onClick={closeDropdown}>
            Contact us
          </Link>
        </li>
        <div className="nav-social-icons">
          <a
            href="https://www.instagram.com/lumierebyvince/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={ins}
              style={{ height: 'auto', width: '4vw', padding: 0 }}
              alt="instgram-icon"
            />
          </a>
          <a
            href=" https://www.tiktok.com/@lumierebyvince?is_from_webapp=1&sender_device=pc"
            target="_blank"
            rel="noopener noreferrer"
            style={{ padding: '3vw' }}
          >
            <img
              src={tiktok}
              style={{ height: 'auto', width: '4vw', padding: 0 }}
              alt="tiktok-icon"
            />
          </a>
          <a
            href=" https://vimeo.com/user210730815"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={vim}
              style={{ height: 'auto', width: '4vw', padding: 0 }}
              alt="tiktok-icon"
            />
          </a>
        </div>
      </ul>
    </nav>
  )
}
