import React from 'react'
import './ContactPageMobile.css'
import contactMobileCover from './imgs/contactCover.jpg'

export const ContactPageMobile: React.FC = () => {
  return (
    <>
      <div className="form-container_contactMobile">
        <img src={contactMobileCover} className="headerPic_contactMobile" />
        <p
          style={{
            paddingBottom: '20px',
            padding: '6vw',
            fontSize: '5vw',
            textAlign: 'center',
          }}
        >
          As no two weddings are the same, I will meet with you to discover
          exactly what you need, ensuring you don’t pay for what you don’t. I’m
          also happy to share my other experiences with weddings, such as
          florists, wedding dresses, venues and more. Feel free to contact me to
          begin your wedding journey.
        </p>
        <strong
          style={{
            paddingBottom: '20px',
            padding: '2vw',
            color: 'rgb(252, 107, 17)',
            textAlign: 'center',
          }}
        >
          If you do not like emails and need a quick reply, why not call me
          instead? My number is 0433030085, available every day.
        </strong>
      </div>
    </>
  )
}
