import React, { useState } from 'react'
import './ContactMobile.css'

import AWS from 'aws-sdk'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { FaPhone } from 'react-icons/fa6'
import { IoIosMail } from 'react-icons/io'

AWS.config.update({
  region: 'ap-southeast-2', // e.g., us-east-1
  accessKeyId: 'AKIA5KXZZDSSKR7BBIQH',
  secretAccessKey: 'ZTl0EI/VsXG3oQxBP0Zb4sZYcVRsFk2Sl9gU1pRY',
})

const ses = new AWS.SES({ apiVersion: '2010-12-01' })

export const ContactMobile: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    weddingDate: '',
    howDidYouHear: '',
  })

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    // SES email parameters
    const params = {
      Destination: {
        ToAddresses: ['info@lumierebyvince.com.au'], // Replace with your recipient's email address
      },
      Message: {
        Body: {
          Text: {
            Data: `Hi Vince you have recived a new customer enquiry: \nCustomer Name: ${
              formData.name
            }\nCustomer Email: ${formData.email}\nCustomer Phone: ${
              formData.phone
            }\nWedding Date: ${formData.weddingDate}\nHow Did You Hear: ${
              formData.howDidYouHear
            }
                \nType of Services required: ${'Not Selected'}`,
          },
        },
        Subject: {
          Data: 'New customer enquires', // Subject of the email
        },
      },
      Source: 'info@lumierebyvince.com.au', // Replace with your sender's email address
    }
    // Send email using SES
    try {
      await ses.sendEmail(params).promise()
      alert('Enquries Send Succesfully!')
    } catch (error) {
      alert('Fail to Send Enquries')
    }
  }
  return (
    <>
      <div className="contact_container_mobile ">
        <h3
          style={{
            paddingTop: '2vh',
            paddingBottom: '2vh',
            fontFamily: 'lovelyFont',
            color: 'goldenrod',
            fontSize: '7.2vw',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          Your Love Story Our Expertise
        </h3>
        <form onSubmit={handleFormSubmit}>
          <div className="form-group_mobile">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
              placeholder="Name"
            />
          </div>
          <div className="form-group_mobile">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email"
              required
            />
          </div>
          <div className="form-group_mobile">
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              required
              placeholder="Phone"
            />
          </div>
          <div className="form-group_mobile">
            <input
              type="text"
              name="weddingDate"
              value={formData.weddingDate}
              onChange={handleInputChange}
              required
              placeholder="Wedding date"
            />
          </div>
          <div className="form-group_mobile">
            <input
              type="text"
              name="howDidYouHear"
              value={formData.howDidYouHear}
              onChange={handleInputChange}
              placeholder="How did you hear about us?"
            />
          </div>
          <div style={{ width: '80px', padding: '5px' }}>
            <button
              className="contactMobile_button"
              style={{
                height: '34px',
                fontSize: 'small',
                background: '#eee',
                margin: '5px 0px 3px 0px',
                textAlign: 'center',
                fontFamily: 'ITC Avant Garde Gothic Light',
                fontWeight: 200,
                fontStretch: 'normal',
                borderRadius: '10px',
              }}
              type="submit"
            >
              Submit
            </button>
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'row', paddingTop: '3vh' }}
          >
            <FaMapMarkerAlt style={{ padding: '2vw' }} />
            <p
              style={{
                paddingTop: '0.6vh',
                fontSize: '3.5vw',
                fontWeight: 'bold',
                paddingLeft: '2vw',
              }}
            >
              Myola court Carindale 4152 QLD
            </p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <FaPhone style={{ padding: '2vw' }} />
            <p
              style={{
                paddingTop: '0.6vh',
                fontSize: '3.5vw',
                fontWeight: 'bold',
                paddingLeft: '2vw',
              }}
            >
              0433030085
            </p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <IoIosMail style={{ padding: '2vw', paddingTop: '1vh' }} />
            <p
              style={{
                paddingTop: '0.6vh',
                fontSize: '3.5vw',
                fontWeight: 'bold',
                paddingLeft: '2vw',
              }}
            >
              z99005588@gmail.com
            </p>
          </div>
          <h3
            style={{
              paddingTop: '2vh',
              paddingBottom: '2vh',
              fontFamily: 'lovelyFont',
              fontSize: '7vw',
              paddingLeft: '18vw',
              fontWeight: 100,
            }}
          >
            Lumiere by Vince
          </h3>
        </form>
      </div>
    </>
  )
}
