import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom'
import { Videography } from './videography'
import { PricingComponent } from './pricingComponent'
import { Gallery } from './Gallery'
import { Contact } from './Contact'
import { Intro } from './Intro'
import { Portrait } from './Portrait'
import Footer from './Footer'
import { HomePage } from './HomePage'
import { Navbar } from './Components/NavBarMobile/NavBarMobile'
import { PhoneOutlined } from '@ant-design/icons'
import { FooterMobile } from './Components/FooterMobile/FooterMobile'
import { VideoDisplay } from './Components/VideoDisplayMobile/VideoDisplay'
import { ContactMobile } from './Components/ContactMobile/ContactMobile'
import logo from '../src/imgs/logo.png'
import mobileLogo from '../src/imgs/logoMobile.png'
import './App.css'
import { useGalleryStore } from './Store/GalleryStore'
import { ContactPageMobile } from './ContactPageMobile'
import { PricingMobile } from './Components/PricingMobile/pricingMobile'
import { isMobile } from 'react-device-detect'

function App() {
  const isGallery = window.location.pathname === '/gallery'
  const setIsGalleryVisible = useGalleryStore(
    (state) => state.updateGalleryVisible
  )
  //Mobile View
  if (isMobile) {
    return (
      <>
        <Router>
          <div className="App">
            <div className="header_box_mobile ">
              <a href="tel:0433030085">
                <PhoneOutlined
                  style={{
                    paddingTop: '1.3vh',
                    marginLeft: '2vw',
                    color: 'black',
                  }}
                />
              </a>
              <div className="logo-coantiner-mobile">
                <Link
                  to="/"
                  onClick={() => {
                    setIsGalleryVisible(false)
                  }}
                >
                  <img src={mobileLogo} alt="logo" className={'logo_mobile'} />
                </Link>
              </div>
              <Navbar />
            </div>

            <div>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/videography" element={<Videography />} />
                <Route path="/videoMobile" element={<VideoDisplay />} />
                <Route path="/pricingMobile" element={<PricingMobile />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route
                  path="/contactPageMobile"
                  element={<ContactPageMobile />}
                />
                <Route path="/Intro" element={<Intro />} />
                <Route path="/portrait" element={<Portrait />} />
              </Routes>
              {isMobile && <ContactMobile />}
              {!isGallery && <FooterMobile />}
            </div>
          </div>
        </Router>
      </>
    )
  }

  // Desktop view
  return (
    <Router>
      <div className="App">
        <div className="header_box">
          <nav className="navbar navbar-inverse" role="navigation">
            <div className="container">
              <div className="logo">
                <Link
                  to="/"
                  onClick={() => {
                    setIsGalleryVisible(false)
                  }}
                >
                  <img src={logo} alt="Sample" className="logo" />
                </Link>
              </div>
            </div>

            <div id="main-nav" className="collapse navbar-collapse navStyle">
              <ul className="nav navbar-nav" id="mainNav">
                <li>
                  <Link to="/Intro">INTRO</Link>
                </li>
                <li>
                  <Link to="/gallery">Photography</Link>
                </li>
                <li>
                  <Link to="/videography">Videography</Link>
                </li>

                <li>
                  <Link to="/pricing">Pricing</Link>
                </li>
                <li>
                  <Link to="/contact">Contact us</Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>

        <div>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/videography" element={<Videography />} />
            <Route path="/pricing" element={<PricingComponent />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/Intro" element={<Intro />} />
            <Route path="/portrait" element={<Portrait />} />
          </Routes>
          {!isGallery && <Footer />}
        </div>
      </div>
    </Router>
  )
}

export default App
