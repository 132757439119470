const galleryImages: string[][] = []

// Define the image categories
const categoryConfig = {
  Dean: 44,
  Gemma: 41,
  Mollie: 43,
  ruby: 41,
  Danny: 46,
  Lara: 44,
  Jaid: 41,
  Damen: 40,
  Sara: 38,
  Jamie: 38,
  Phyllis: 40,
  Stefanie: 42,
  Anna: 36,
  Anni: 38,
  Wendy: 38,
} as Record<string, number>

for (const category of Object.keys(categoryConfig)) {
  const images = []

  // Get the number of images for the current category
  const numImages = categoryConfig[category]

  // Loop through images and import them dynamically
  for (let i = 1; i <= numImages; i++) {
    const imageName = `${category}/${category}-${i}`
    const image = require(`../src/imgs/Gallery/${imageName}.jpg`)
    images.push(image)
  }

  // Add the images to the galleryImages array
  galleryImages.push(images)
}

export { galleryImages }
