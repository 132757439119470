import React from 'react'
import './pricingMobile.css'
import pricing1 from '../../../src/imgs/pricing/pricing1.jpg'
import pricing2 from '../../../src/imgs/pricing/pricing2.jpg'
import pricing3 from '../../../src/imgs/pricing/pricing3.jpg'
import pricing4 from '../../../src/imgs/pricing/pricing4.jpg'
import pricingMobileCover from '../../../src/imgs/pricingMobileCover.jpg'
import { FAQComponent } from '../../faqComponent'
import FaqMobileComponent from '../FAQMobile/FaqMobileComponent'

export const PricingMobile: React.FC = () => {
  return (
    <div className="pricing-container_mobile">
      <img src={pricingMobileCover} className="headerPic_pricingMobile" />
      <p
        style={{
          padding: '1vw',
          fontSize: '3.3vw',
          textAlign: 'center',
        }}
      >
        I understand the excitement of newlyweds waiting for their wedding
        photos. However, every time I finish a wedding shoot, I’m more excited
        to dive into reviewing the photos.
      </p>
      <p
        style={{
          padding: '1vw',
          fontSize: '3.3vw',
          textAlign: 'center',
        }}
      >
        Sometimes, even after finishing a shoot in the middle of the night, I
        eagerly scroll through the day’s captures on my computer. I can’t wait
        to see the final edited image than anyone else.
      </p>
      <p
        style={{
          paddingTop: 0,
          padding: '8vw',
          fontSize: '3.3vw',
          textAlign: 'center',
        }}
      >
        So, you will see your wedding photos within a week :)
      </p>
      <div className="bordered-div">
        <img src={pricing1} alt="Image 1" className="pricing-image_mobile" />
        <div className="pricing-text_mobile">
          <h3 className="pricing-price"> Photography </h3>
          <h3 className="pricing-price" style={{ textDecoration: 'underline' }}>
            $2250
          </h3>
          <p>-1 Photographer for 8 hours of coverage</p>
          <p>
            - Unlimited high-resolution color-edited JEPG images{' '}
            <span style={{ color: 'rgb(252, 107, 17)' }}>( original size)</span>
            will be delivered via cloud
          </p>
          <p>
            <span style={{ color: 'rgb(252, 107, 17)' }}>
              All your photos will be completed within a week,
            </span>{' '}
            minimising the waiting time for your family and friends{' '}
          </p>
          <p>
            -{' '}
            <span style={{ color: 'rgb(252, 107, 17)' }}>
              Rainy day guarantee{' '}
            </span>
            (Check FAQs)
          </p>
          <p>-Pre-wedding consultation</p>
        </div>
      </div>
      <div className="bordered-div">
        <img src={pricing2} alt="Image 1" className="pricing-image_mobile" />
        <div className="pricing-text_mobile">
          <h3 className="pricing-price"> Videography </h3>
          <h3 className="pricing-price" style={{ textDecoration: 'underline' }}>
            $2350
          </h3>
          <p>
            - 1 Videographer for{' '}
            <span style={{ color: 'rgb(252, 107, 17)' }}>8</span> hours of
            coverage
          </p>
          <p style={{ color: 'rgb(252, 107, 17)' }}>
            - Complimentary drone shooting
          </p>
          <p>
            - Up to 6-minute HD wedding video trailer featuring your chosen
            music (two options)
          </p>
          <p>- HD footage with live audio recording</p>
          <p>- Pre-wedding consultation</p>
        </div>
      </div>
      <div className="bordered-div">
        <img src={pricing3} alt="Image 1" className="pricing-image_mobile" />
        <div className="pricing-text_mobile">
          <h3 className="pricing-price"> Combo </h3>
          <h3 className="pricing-price" style={{ textDecoration: 'underline' }}>
            $4600
          </h3>
          <p>
            - Includes all the services from photography & Videography packages
          </p>
          <p style={{ color: 'rgb(252, 107, 17)' }}>
            - Free second shooter for photography (Valued at $500)
          </p>
          <p>
            <span style={{ color: 'rgb(252, 107, 17)' }}>Why combo?</span> -
            Booking photography and videography services from the same studio
            offer the advantage of long-term collaboration and mutual
            understanding.
          </p>
          <p>
            We possess a deeper understanding of how to coordinate with each
            other; if photographers and videographers from different studios are
            involved, there might be a lack of mutual understanding that could
            subtly influence the flow of your wedding day.
          </p>
        </div>
      </div>

      <div className="bordered-div">
        <img src={pricing4} alt="Image 1" className="pricing-image_mobile" />
        <div className="pricing-text_mobile">
          <h3 className="pricing-price" style={{ textDecoration: 'underline' }}>
            Additional Costs
          </h3>

          <p>- Second shooter - Photography: $500</p>
          <p>- Second shooter - Videography: $500</p>
          <p>- Outside QLD traveling fee: $600 (per person)</p>
          <p>- Same-day video editing: $1000</p>
          <p>
            - Extra photo retouching: $7 each (includes liquify, teeth
            whitening, object & mark removal, etc)
          </p>
          <p>- Albums (click here)</p>
        </div>
      </div>
      <div className="FAQ-div">
        <FaqMobileComponent />
      </div>
    </div>
  )
}
