import React, { useState } from 'react'
import './VideoDisplay.css'
import { Divider } from '@mui/material'
import Emma from '../../../src/imgs/videoCover/Emma.jpg'
import Giovi from '../../../src/imgs/videoCover/Giovi.jpg'
import Jenny from '../../../src/imgs/videoCover/Jenny.jpg'
import Jonny from '../../../src/imgs/videoCover/Jonny.jpg'
import Wendy from '../../../src/imgs/videoCover/Wendy.jpg'
import { Link } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

interface Video {
  title: string
  preview: string
  videoUrl: string
}

const videos: Video[] = [
  {
    title: 'Wendy + Peter | St Kilda Beach',
    preview: Wendy,
    videoUrl:
      'https://player.vimeo.com/video/885907587?h=4b0909b848&autoplay=1&loop=1',
  },

  {
    title: 'Georgie + Jonny | Ballarat',
    preview: Jonny,
    videoUrl:
      'https://player.vimeo.com/video/881981343?h=cabd336445&autoplay=1&loop=1',
  },
  {
    title: 'Giovi + Andrew | Glen Iris',
    preview: Giovi,
    videoUrl:
      'https://player.vimeo.com/video/881957585?h=131823e72e&autoplay=1&loop=1',
  },
  {
    title: 'Jenny + Ardian | Melbourne CBD',
    preview: Jenny,
    videoUrl:
      'https://player.vimeo.com/video/885906935?h=01a7c13256&autoplay=1&loop=1',
  },
  {
    title: 'Emma + Xiaolong | Dandenong mountain',
    preview: Emma,
    videoUrl:
      'https://player.vimeo.com/video/885905848?h=b49e777a1e&autoplay=1&loop=1',
  },
]

interface VideoDisplayProps {
  hideHeader?: boolean
  setIsGalleryVisible?: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedImageSetIndex?: React.Dispatch<React.SetStateAction<number | null>>
}

export const VideoDisplay: React.FC<VideoDisplayProps> = ({
  hideHeader,
  setIsGalleryVisible,
  setSelectedImageSetIndex,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [selectedVideo, setSelectedVideo] = useState<Video | null>(null)

  const handleOpenModal = (video: Video) => {
    setSelectedVideo(video)
    setModalIsOpen(true)
  }

  const handleCloseModal = () => {
    setSelectedVideo(null)
    setModalIsOpen(false)
  }
  console.log('isMobile', isMobile)
  return (
    <>
      {isMobile && (
        <section
          id="headPic_section"
          className="videDisplay_headPic_image_mobile"
        >
          <div className="videDisplay_container_mobile">
            <div className="videDisplay_headPic_section_mobile">
              <div className="videDisplay_row_mobile">
                <div className="col-md-12"></div>
              </div>
            </div>
          </div>
        </section>
      )}

      <div className={'center-container_mobile'}>
        {isMobile && (
          <>
            <p className={'center-text_mobile'}>
              <Link to="/">Photography</Link>
            </p>
            <p className={'center-text_mobile'}>
              <Link to="/videoMobile">Videography</Link>
            </p>
          </>
        )}
      </div>
      <Divider />
      <div className="centered-container">
        {videos.map((video, index) => (
          <div key={index}>
            <div style={{ paddingTop: '2%', paddingBottom: '5%' }}></div>
            <div className="cover" onClick={() => handleOpenModal(video)}>
              <div
                className="image-container"
                style={{
                  backgroundImage: `url(${video.preview})`,
                }}
              ></div>
            </div>
            {/* <p
              style={{
                fontFamily: 'freight-sans-pro',
                color: '#000',
                fontWeight: 400,
                fontStyle: 'normal',
                fontSize: '2vw',
                letterSpacing: '.03em',
                lineHeight: '1.6em',
                textAlign: 'left',
                paddingTop: 0,
              }}
            >
              {video.title}
            </p> */}
          </div>
        ))}
        {modalIsOpen && selectedVideo && (
          <div className="modal">
            <div className="modal-content">
              <div className="video-container-modal">
                <span className="close-button" onClick={handleCloseModal}>
                  &times;
                </span>
                <div style={{ paddingTop: '56.25%', position: 'relative' }}>
                  <iframe
                    src={selectedVideo.videoUrl}
                    title={selectedVideo.title}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                    }}
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
