import React from 'react'
import './imagesDisplay.css'
import { Divider } from '@mui/material'

// 导入图片
import anni from '../src/imgs/cover/1.jpg'
import chellee from '../src/imgs/cover/2.jpg'
import danny from '../src/imgs/cover/3.jpg'
import gemma from '../src/imgs/cover/4.jpg'
import jaid from '../src/imgs/cover/5.jpg'
import jamie from '../src/imgs/cover/6.jpg'
import kristen from '../src/imgs/cover/7.jpg'
import lara from '../src/imgs/cover/8.jpg'
import mollie from '../src/imgs/cover/9.jpg'
import phyllis from '../src/imgs/cover/10.jpg'
import ruby from '../src/imgs/cover/11.jpg'
import sarah from '../src/imgs/cover/12.jpg'
import stefanie from '../src/imgs/cover/13.jpg'
import taylah from '../src/imgs/cover/14.jpg'
import wendy from '../src/imgs/cover/15.jpg'

import chelleeMobile from '../src/imgs/mobileCover/Chellee1.jpg'
import GemmMobile from '../src/imgs/mobileCover/Gemm2.jpg'
import MollieMobile from '../src/imgs/mobileCover/Mollie3.jpg'
import RubyMobile from '../src/imgs/mobileCover/Ruby4.jpg'
import DannyMobile from '../src/imgs/mobileCover/Danny5.jpg'
import LaraMobile from '../src/imgs/mobileCover/Lara6.jpg'
import JaidMobile from '../src/imgs/mobileCover/Jaid7.jpg'
import KristenMobile from '../src/imgs/mobileCover/Kristen8.jpg'
import SaraMobile from '../src/imgs/mobileCover/Sarah9.jpg'
import JamieMobile from '../src/imgs/mobileCover/Jamie10.jpg'
import PhyllisMobile from '../src/imgs/mobileCover/Phyllis11.jpg'
import StefanieMobile from '../src/imgs/mobileCover/Stefanie12.jpg'
import TaylahMobile from '../src/imgs/mobileCover/Taylah13.jpg'
import AnnihMobile from '../src/imgs/mobileCover/Anni14.jpg'
import WendyMobile from '../src/imgs/mobileCover/Wendy15.jpg'
import { Link } from 'react-router-dom'
import { useGalleryStore } from './Store/GalleryStore'

import { isMobile } from 'react-device-detect'

const images = [
  anni,
  chellee,
  danny,
  gemma,
  jaid,
  jamie,
  kristen,
  lara,
  mollie,
  phyllis,
  ruby,
  sarah,
  stefanie,
  taylah,
  wendy,
]

const mobileImages = [
  chelleeMobile,
  GemmMobile,
  MollieMobile,
  RubyMobile,
  DannyMobile,
  LaraMobile,
  JaidMobile,
  KristenMobile,
  SaraMobile,
  JamieMobile,
  PhyllisMobile,
  StefanieMobile,
  TaylahMobile,
  AnnihMobile,
  WendyMobile,
]

const albumNames = [
  'Chellee & Dean',
  'Gemma & Jamie',
  'Mollie & James',
  'Ruby & Tyson',
  'Danny & Dame',
  'Lara & Shaun',
  'Jaid & Mark',
  'Kristen & Darren',
  'Sarah & Jye',
  'Jamie & Tim',
  'Phyllis & Arthur',
  'Stefanie & Jayme',
  'Taylah & Sinclair',
  'Anni & Steve',
  'Wendy & Peter',
]

interface ImagesDisplayProps {
  hideHeader?: boolean
  setSelectedImageSetIndex?: React.Dispatch<React.SetStateAction<number | null>>
}

export const ImagesDisplay: React.FC<ImagesDisplayProps> = ({
  hideHeader,
  setSelectedImageSetIndex,
}) => {
  const divs = []
  const setIsGalleryVisible = useGalleryStore(
    (state) => state.updateGalleryVisible
  )

  const imagesToMap = isMobile ? mobileImages : images
  const imagesToDisplay = isMobile ? 2 : 3
  for (let i = 0; i < 8; i++) {
    const rowImages = imagesToMap.slice(
      i * imagesToDisplay,
      i * imagesToDisplay + imagesToDisplay
    ) // 每行最多显示三张图片
    divs.push(
      <div className={isMobile ? 'imageDisplay_row_mobile' : 'row'} key={i}>
        {rowImages.map((imageUrl, index) => (
          <div
            className={
              isMobile
                ? 'customImage-container_mobile'
                : 'customImage-container'
            }
          >
            <img
              src={imageUrl}
              alt={`${i * imagesToDisplay + index + 1}`}
              className={
                isMobile ? 'imageDisplay_Image_mobile' : 'imageDisplay_Image'
              }
              style={{
                maxHeight: '662px',
                maxWidth: '441px',
                padding: '50px',
                margin: 0,
              }}
            />
            {isMobile &&
              (i * imagesToDisplay + index !== 14 ? (
                <p>{albumNames[i * imagesToDisplay + index]}</p>
              ) : (
                <p
                  style={{
                    textAlign: 'unset',
                    paddingLeft: '16vw',
                  }}
                >
                  {albumNames[i * imagesToDisplay + index]}
                </p>
              ))}

            <div
              className="overlay"
              onClick={() => {
                setIsGalleryVisible && setIsGalleryVisible(true)
                setSelectedImageSetIndex &&
                  setSelectedImageSetIndex(i * imagesToDisplay + index)
              }}
            >
              <div className="text" style={{ fontSize: '1vw' }}>
                {albumNames[i * imagesToDisplay + index]}
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }

  return (
    <>
      {!hideHeader &&
        (isMobile ? (
          <section
            id="imageDisplay_headPic_section"
            className="imageDisplay_headPic_image_mobile"
          >
            <div className="imageDisplay_container_mobile">
              <div className="imageDisplay_headPic_section_mobile">
                <div className="imageDisplay_row_mobile">
                  <div className="col-md-12"></div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section id="imageDisplay_headPic_section" className="headPic_image">
            <div>
              <div className="imageDisplay_container">
                <div className="imageDisplay_headPic_section">
                  <div className="imageDisplay_row_mobile">
                    <div className="col-md-12"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ))}

      <div
        className={isMobile ? 'center-container_mobile' : 'center-container'}
      >
        {!hideHeader &&
          (isMobile ? (
            <>
              <p className={'center-text_mobile'}>
                <Link className="link-hover-effect" to="/gallery">
                  Photography
                </Link>
              </p>
              <p className={'center-text_mobile'}>
                <Link className="link-hover-effect" to="/videoMobile">
                  Videography
                </Link>
              </p>
            </>
          ) : (
            <p
              className={'center-text'}
              style={{ fontSize: '1.3vw', fontFamily: 'cochinRegular' }}
            >
              photography & videography
            </p>
          ))}
      </div>
      <Divider />
      <div
        className={isMobile ? 'images-container_mobile' : 'images-container'}
      >
        {divs}
      </div>
    </>
  )
}
