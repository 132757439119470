import React from 'react'
import { Link } from 'react-router-dom'
import { FaVimeo } from 'react-icons/fa'
import './FooterMobile.css'
import ins from '../../../src/imgs/logos/ins.png'
import tiktok from '../../../src/imgs/logos/tiktok.png'
import vim from '../../../src/imgs/logos/vim.png'
import { Divider } from 'antd'
import { useGalleryStore } from '../../Store/GalleryStore'

export const FooterMobile: React.FC = () => {
  const setIsGalleryVisible = useGalleryStore(
    (state) => state.updateGalleryVisible
  )
  return (
    <>
      <footer className="mobileFooter">
        <div
          className="mobileFooterContainer"
          style={{ backgroundColor: 'white' }}
        >
          <div className="mobileFooter-section" style={{ margin: 0 }}>
            <nav className="mobileFooter-nav_mobile">
              <ul
                style={{
                  display: 'flex',
                  paddingLeft: '19vw',
                  justifyContent: 'space-between',
                }}
              >
                <li onClick={() => setIsGalleryVisible(false)}>
                  <Link to="/">Home</Link>
                </li>
                <li onClick={() => setIsGalleryVisible(false)}>
                  <Link to="/intro">Intro</Link>
                </li>
                <li onClick={() => setIsGalleryVisible(false)}>
                  <Link to="/gallery">Photo</Link>
                </li>
                <li onClick={() => setIsGalleryVisible(false)}>
                  <Link to="/videography">Video</Link>
                </li>
                <li onClick={() => setIsGalleryVisible(false)}>
                  <Link to="/pricing">Pricing</Link>
                </li>
                <li></li>
              </ul>
            </nav>

            <div className="mobileFooter_social-icons">
              <a
                href="https://www.instagram.com/lumierebyvince/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={ins}
                  style={{ height: 'auto', width: '4vw', padding: 0 }}
                  alt="instgram-icon"
                />
              </a>
              <a
                href=" https://www.tiktok.com/@lumierebyvince?is_from_webapp=1&sender_device=pc"
                target="_blank"
                rel="noopener noreferrer"
                style={{ padding: '3vw' }}
              >
                <img
                  src={tiktok}
                  style={{ height: 'auto', width: '4vw', padding: 0 }}
                  alt="tiktok-icon"
                />
              </a>
              <a
                href=" https://vimeo.com/user210730815"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={vim}
                  style={{ height: 'auto', width: '4vw', padding: 0 }}
                  alt="vim-icon"
                />
              </a>
            </div>
          </div>
        </div>
        <Divider style={{ margin: 0, backgroundColor: 'black' }} />
        {/* Bottom Footer */}
        <div className="mobileFooter-bottom">
          <p style={{ paddingTop: '1.5vh' }}>
            &copy; {new Date().getFullYear()} lumierebyvince. All Rights
            Reserved.
          </p>
        </div>
      </footer>
    </>
  )
}
