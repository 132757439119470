import React from 'react'
import './pricingComponent.css'
import pricing1 from '../src/imgs/pricing/pricing1.jpg'
import pricing2 from '../src/imgs/pricing/pricing2.jpg'
import pricing3 from '../src/imgs/pricing/pricing3.jpg'
import pricing4 from '../src/imgs/pricing/pricing4.jpg'
import { FAQComponent } from './faqComponent'

export const PricingComponent: React.FC = () => {
  return (
    <div className="pricing-container">
      <h1 className="centered-title">PRICING AND FAQ’s</h1>
      <div className="pricing-items">
        {/* Wedding Photography Package */}
        <div className="pricing-item left">
          <img
            src={pricing1}
            alt="Image 1"
            className="pricing-image"
            style={{ maxWidth: '100%' }}
          />
          <div className="pricing-text">
            <h3 className="pricing-price">
              Wedding Photography Package - $2250
            </h3>
            <div>
              <p>-1 Photographer for 8 hours of coverage</p>
              <p>
                - Unlimited high-resolution color-edited JEPG images{' '}
                <span style={{ color: 'rgb(252, 107, 17)' }}>
                  ( original size)
                </span>
                will be delivered via cloud
              </p>
              <p>
                <span style={{ color: 'rgb(252, 107, 17)' }}>
                  All your photos will be completed within a week,
                </span>{' '}
                minimising the waiting time for your family and friends{' '}
              </p>
              <p>
                -{' '}
                <span style={{ color: 'rgb(252, 107, 17)' }}>
                  Rainy day guarantee{' '}
                </span>
                (Check FAQs)
              </p>
              <p>-Pre-wedding consultation</p>
            </div>
          </div>
        </div>

        {/* Wedding Videography Package */}
        <div className="pricing-item right">
          <div className="pricing-text reverse">
            <h3 className="pricing-price">
              Wedding Videography Package - $2350
            </h3>
            <div>
              <p>
                - 1 Videographer for{' '}
                <span style={{ color: 'rgb(252, 107, 17)' }}>8</span> hours of
                coverage
              </p>
              <p style={{ color: 'rgb(252, 107, 17)' }}>
                - Complimentary drone shooting
              </p>
              <p>
                - Up to 6-minute HD wedding video trailer featuring your chosen
                music (two options)
              </p>
              <p>- HD footage with live audio recording</p>
              <p>- Pre-wedding consultation</p>
            </div>
          </div>
          <img
            src={pricing3}
            alt="Image 2"
            className="pricing-image"
            style={{ maxWidth: '100%' }}
          />
        </div>

        {/* Wedding Combo */}
        <div className="pricing-item left">
          <img
            src={pricing2}
            alt="Image 3"
            className="pricing-image"
            style={{ maxWidth: '100%' }}
          />
          <div className="pricing-text">
            <h3 className="pricing-price">
              Wedding Combo - $4600{' '}
              <span style={{ color: 'rgb(252, 107, 17)' }}>(Special Deal)</span>
            </h3>
            <div>
              <p>
                - Includes all the services from photography & Videography
                packages
              </p>
              <p style={{ color: 'rgb(252, 107, 17)' }}>
                - Free second shooter for photography (Valued at $500)
              </p>
              <div></div>
              <p>
                <span style={{ color: 'rgb(252, 107, 17)' }}>Why combo?</span> -
                Booking photography and videography services from the same
                studio offer the advantage of long-term collaboration and mutual
                understanding.
              </p>
              <p>
                We possess a deeper understanding of how to coordinate with each
                other; if photographers and videographers from different studios
                are involved, there might be a lack of mutual understanding that
                could subtly influence the flow of your wedding day.
              </p>
            </div>
          </div>
        </div>

        {/* Additional Costs */}
        <div className="pricing-item right">
          <div className="pricing-text reverse">
            <h3 className="pricing-price">Additional Costs</h3>
            <div>
              <p>- Second shooter - Photography: $500</p>
              <p>- Second shooter - Videography: $500</p>
              <p>- Outside QLD traveling fee: $600 (per person)</p>
              <p>- Same-day video editing: $1000</p>
              <p>
                - Extra photo retouching: $7 each (includes liquify, teeth
                whitening, object & mark removal, etc)
              </p>
              <p>- Albums (click here)</p>
            </div>
          </div>
          <img
            src={pricing4}
            alt="Image 4"
            className="pricing-image"
            style={{ maxWidth: '100%' }}
          />
        </div>
      </div>
      <div className="faq-container">
        <FAQComponent />
      </div>
    </div>
  )
}
