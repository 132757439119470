import React from 'react'
import { Link } from 'react-router-dom'
import { FaTiktok, FaInstagram, FaLinkedin, FaVimeo } from 'react-icons/fa' // Assuming you have these icons available
import './Footer.css'

const Footer: React.FC = () => {
  return (
    <>
      <footer className="footer">
        <div className="container">
          {/* Section 1: Logo and Text */}
          <div className="footer-section">
            <div className="footer-logo">
              <Link to="/" className="scroll-link"></Link>
            </div>
            <h2>PhotoGraphy</h2>
            <p>
              We offer a variety of photography and videography services to meet
              your budget and needs.
            </p>
          </div>

          {/* Section 2: Quick Links */}
          <div className="footer-section">
            <nav className="footer-nav">
              <h2>Quick Links</h2>
              <ul>
                <li>
                  <Link to="/intro">Intro</Link>
                </li>
                <li>
                  <Link to="/gallery">Photography</Link>
                </li>
                <li>
                  <Link to="/videography">Videography</Link>
                </li>
                <li>
                  <Link to="/pricing">Pricing</Link>
                </li>
                <li>
                  <Link to="/contact">Contact us</Link>
                </li>
              </ul>
            </nav>
          </div>

          {/* Section 3: Contact Information */}
          <div className="footer-section">
            <h2>Contact Info</h2>
            <p>Myola court Carindale 4152 QLD</p>
            <p>Phone: 0433030085</p>
            <p>Email: z99005588@gmail.com</p>
          </div>

          {/* Section 4: Follow Us */}
          <div className="footer-section">
            <h2>Follow Us</h2>
            <div className="social-icons" style={{ paddingRight: 0 }}>
              <a
                href=" https://www.tiktok.com/@lumierebyvince?is_from_webapp=1&sender_device=pc"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTiktok />
              </a>
              <a
                href="https://www.instagram.com/lumierebyvince/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
              </a>
              <a
                href=" https://vimeo.com/user210730815"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaVimeo />
              </a>
            </div>
            <div style={{ paddingLeft: '2vw' }}>
              <a
                href="https://www.lumierebyvince.com.au/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>Brisbane wedding videographer</p>
              </a>
              <a
                href="https://www.lumierebyvince.com.au/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>Wedding videography brisbane</p>
              </a>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <a
            href="https://www.lumierebyvince.com.au/sitemap.xml"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p style={{ fontSize: '0.5vw', paddingTop: '1vw', color: '#333' }}>
              &copy; {new Date().getFullYear()} lumierebyvince. All Rights
              Reserved. site map
            </p>
          </a>
        </div>
      </footer>
    </>
  )
}

export default Footer
