// Update your Contact component
import React, { useState } from 'react'
import './Contact.css'
import AWS from 'aws-sdk'

AWS.config.update({
  region: 'ap-southeast-2', // e.g., us-east-1
  accessKeyId: 'AKIA5KXZZDSSKR7BBIQH',
  secretAccessKey: 'ZTl0EI/VsXG3oQxBP0Zb4sZYcVRsFk2Sl9gU1pRY',
})

const ses = new AWS.SES({ apiVersion: '2010-12-01' })

export const Contact: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    weddingDate: '',
    howDidYouHear: '',
    photography: '',
    videography: '',
  })

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    // SES email parameters
    const params = {
      Destination: {
        ToAddresses: ['info@lumierebyvince.com.au'], // Replace with your recipient's email address
      },
      Message: {
        Body: {
          Text: {
            Data: `Hi Vince you have recived a new customer enquiry: \nCustomer Name: ${
              formData.name
            }\nCustomer Email: ${formData.email}\nCustomer Phone: ${
              formData.phone
            }\nWedding Date: ${formData.weddingDate}\nHow Did You Hear: ${
              formData.howDidYouHear
            }
            \nType of Services required: ${
              formData.photography
                ? 'Photography'
                : formData.videography
                ? 'videography'
                : 'Not Selected'
            }`,
          },
        },
        Subject: {
          Data: 'New customer enquires', // Subject of the email
        },
      },
      Source: 'info@lumierebyvince.com.au', // Replace with your sender's email address
    }
    // Send email using SES
    try {
      await ses.sendEmail(params).promise()
      alert('Enquries Send Succesfully!')
    } catch (error) {
      alert('Fail to Send Enquries')
    }
  }

  return (
    <>
      <div className="form-container_contact">
        <p style={{ paddingRight: '120px', paddingBottom: '5px' }}>
          As no two weddings are the same, I will meet with you to discover
          exactly what you need, ensuring you don’t pay for what you don’t. I’m
          also happy to share my other experiences with weddings, such as
          florists, wedding dresses, venues and more. Feel free to contact me to
          begin your wedding journey.
        </p>
        <strong
          style={{
            paddingRight: '120px',
            paddingBottom: '5px',
            color: 'rgb(252, 107, 17)',
          }}
        >
          If you do not like emails and need a quick reply, why not call me
          instead? My number is 0433030085, available every day.
        </strong>
        <form onSubmit={handleFormSubmit}>
          <div
            className="form-group"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <label>
              Name
              <span className="required-asterisk" title="required">
                *
              </span>
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
              style={{ width: '50%' }}
            />
            <div
              className="form-group checkbox-group"
              style={{ marginLeft: '10px', flexDirection: 'row' }}
            >
              <div style={{ display: 'flex' }}>
                <label className="checkbox-label">Photography</label>
                <input
                  type="checkbox"
                  name="photography"
                  onChange={handleInputChange}
                />
              </div>
              <div style={{ display: 'flex' }}>
                <label className="checkbox-label">Videography</label>
                <input
                  type="checkbox"
                  name="videography"
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>
              Email{' '}
              <span className="required-asterisk" title="required">
                *
              </span>
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>
              Phone{' '}
              <span className="required-asterisk" title="required">
                *
              </span>
            </label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>
              Wedding date{' '}
              <span className="required-asterisk" title="required">
                *
              </span>
            </label>
            <input
              type="date"
              name="weddingDate"
              value={formData.weddingDate}
              onChange={handleInputChange}
              required
              style={{ fontSize: '0.5vw' }}
            />
          </div>
          <div className="form-group">
            <label>How did you hear about us?</label>
            <input
              type="text"
              name="howDidYouHear"
              value={formData.howDidYouHear}
              onChange={handleInputChange}
            />
          </div>
          <div style={{ width: '5vw', paddingTop: '10px' }}>
            <button type="submit" style={{ color: '#777' }}>
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  )
}
