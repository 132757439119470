import React, { useState } from 'react'
import './FaqMobileComponent.css'

interface Faq {
  question: string
  answer: string
}

const FaqMobileComponent: React.FC = () => {
  // This state will track the open questions
  const [openFaqIndex, setOpenFaqIndex] = useState<number | null>(null)

  const faqs: Faq[] = [
    {
      question: 'Why second shooter is important?',
      answer:
        'At weddings, one photographer’s primary focus is often on the couple. But the participation of family and guests is equally vital for the overall ambience. Having a second shooter who can  capture moments with guests adds a more comprehensive touch to the entire wedding experience.',
    },
    {
      question: 'What if it rains on my wedding day?',
      answer:
        'Videos have the power to convey emotions in a way that photos may not fully capture. The exchange of vows, the laughter, the tears, and other emotional moments are more vividly experienced through moving images and sound.',
    },
    {
      question: 'Why videography is important?',
      answer:
        'Our camera is fully weather-resistant and capable of shooting on heavy rainy days. We also provide transparent wedding umbrellas that add an elegant touch to the photos. If during a storm or lightning, there is no suitable location in the venue for photography, we will offer you another two-hour location shoot and only charge $200 for travel fees (weekday only).',
    },
    {
      question: 'Do you travel to other cities?',
      answer:
        'Certainly, we’d love to fly to different destinations to capture various styles of wedding photos. I only charge $700, which is just enough to cover my flight tickets, accommodation, and car rental.',
    },
  ]

  const toggleFaq = (index: number): void => {
    setOpenFaqIndex(openFaqIndex === index ? null : index)
  }

  return (
    <div className="faq-mobile-container">
      <h3 style={{ textAlign: 'start' }}>FAQs</h3>
      {faqs.map((faq, index) => (
        <div
          key={index}
          className={`faq-mobile-item ${openFaqIndex === index ? 'open' : ''}`}
        >
          <div className="faq-mobile-question" onClick={() => toggleFaq(index)}>
            {faq.question}
            <span className="faq-mobile-toggle">
              {openFaqIndex === index ? '-' : '+'}
            </span>
          </div>
          <div className="faq-mobile-answer">
            {openFaqIndex === index && (
              <p style={{ fontSize: '4vw' }}>{faq.answer}</p>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

export default FaqMobileComponent
