interface DesktopComponentProps {
  currentIndex: number
  showPreviousImage: () => void
  showNextImage: () => void
  galleryImages: string[][]
  selectedImageSetIndex: number | null
  albumNames: string[]
  setHoveredIndex: React.Dispatch<React.SetStateAction<number | null>>
  setSelectedImageSetIndex: React.Dispatch<React.SetStateAction<number | null>>
  hoveredIndex: number | null
  thumbnails: string[]
}

export const DesktopComponent: React.FC<DesktopComponentProps> = ({
  currentIndex,
  showPreviousImage,
  showNextImage,
  galleryImages,
  selectedImageSetIndex,
  albumNames,
  setHoveredIndex,
  setSelectedImageSetIndex,
  hoveredIndex,
  thumbnails,
}) => {
  return (
    <div className="gallery-container">
      {/* List of album names */}
      <div className="album-list">
        <ul>
          {albumNames.map((albumName, index) => (
            <li
              key={index}
              className={
                selectedImageSetIndex === index ? 'selected-album' : ''
              }
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
              onClick={() => setSelectedImageSetIndex(index)}
            >
              <div className="album-item">
                <span className="album-name">{albumName}</span>
                {hoveredIndex === index && (
                  <div className="thumbnail-container">
                    <img
                      src={thumbnails[index]}
                      alt={`Thumbnail of ${albumName}`}
                    />
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
      <img
        className="gallery-image"
        src={galleryImages[selectedImageSetIndex || 0][currentIndex]}
        alt={`Image ${currentIndex + 1}`}
      />

      <div className="button-wrapper">
        {/* Left arrow button */}
        <button onClick={showPreviousImage} className="arrow-button">
          Back
        </button>
        <p style={{ minWidth: '55px', fontSize: '14px' }}>
          {+currentIndex +
            1 +
            '/' +
            galleryImages[selectedImageSetIndex ? selectedImageSetIndex : 0]
              .length}
        </p>
        {/* Right arrow button */}
        <button onClick={showNextImage} className="arrow-button">
          Next
        </button>
      </div>
    </div>
  )
}
